export const gameDifficulties = [
    {
        level: 'easy',
        label: 'Easy (30 attempts)😍',
        attempts: 30
    },
    {
        level: 'medium',
        label: 'Medium (20 attempts)🙂',
        attempts: 20
    },
    {
        level: 'hard',
        label: 'Hard (10 attempts)😦',
        attempts: 10
    },
];
